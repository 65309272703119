import React, { useEffect, useRef, useState } from 'react';

import styles from './assets/Wrapper.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router';

import Banner from './components/Banner/Banner.jsx';
import Navbar from './components/Navbar.jsx';
import Sidebar from './components/Sidebar/Sidebar.jsx';
import Topbar from './components/Topbar/Topbar.jsx';

import findMyPath from './utils/findMyPath.js';
import getCookieItem from './utils/getCookieItem.js';
import refreshPage from './utils/refreshPage.js';

import cacheCleaner from './hooks/useCacheClean.js';
import useIntersectionObserver from './hooks/useIntersectionObserver.js';
import useMarketingScript from './hooks/useMarketingScript.js';
import utmDataBuilder from './hooks/useUtmDataBuilder.js';
import utmDataSender from './hooks/useUtmDataSender.js';

import BackOffice from './pages/BackOffice/backOffice.js';
import Landing from './pages/Landing/landing.js';
import UI from './pages/UI/ui.js';

import { UserAreasSring, formActions, storageKeys, storageValues } from './constants/Enum.jsx';
import {
  codeMetaVideoaskPaths,
  dashboardPath,
  googleVideoaskPaths,
  groupPath,
  landingPaths,
  metaVideoaskPaths,
  orderBasketPath,
  orderPackagePath,
  orderPaymentDetailPath,
  orderTriallessonPath,
  ordersPath,
  privatePath,
  schedulePath,
  sessionsPath,
  speakerDetailsPath,
  speakersPath,
  tiktokPaths,
} from './constants/URLPath.js';
import { setAreaDetails } from './services/apiServices/authServices.js';

const routes = (auth, pathArray = false) => {
  function UISideTopFoot() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;
    const [sidebarOpen, setSidebarOpen] = useState(true);

    cacheCleaner(dispatch, navigate, auth);
    useMarketingScript();

    useEffect(() => {
      const storageKey = JSON.parse(sessionStorage.getItem(storageKeys.utmDataInsert));
      if (userInfo?.id !== undefined && storageKey?.value && storageKey?.form === formActions.register.key) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' ? (
      <Navigate to='/account/login' replace />
    ) : lengthCurrentUserClaim ? (
      <React.Fragment>
        <Banner userArea={areaEnum} />
        <div className={styles.wrapper}>
          <Sidebar sidebarIsOpen={sidebarOpen} handleSideBar={(val) => setSidebarOpen(val)} />
          <div className={styles.contentWrapper}>
            <Topbar handleSideBar={(val) => setSidebarOpen(val)} />
            <Outlet />
          </div>
        </div>
      </React.Fragment>
    ) : (
      <Navigate to='/unauthorized' replace />
    );
  }
  function Dashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;
    const propsToPass = {
      currentUserClaim: currentUserClaim,
    };
    const currentPathname = window.location.pathname;

    cacheCleaner(dispatch, navigate, auth);
    useMarketingScript();

    useEffect(() => {
      const storageKey = JSON.parse(sessionStorage.getItem(storageKeys.utmDataInsert));
      if (userInfo?.id !== undefined && storageKey?.value && storageKey?.form === formActions.register.key) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      if (auth !== '' && currentPathname === '/') {
        navigate(findMyPath('dashboard'));
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' ? (
      <Navigate to='/account/login' replace />
    ) : (
      <div>
        {lengthCurrentUserClaim ? <Banner userArea={areaEnum} /> : null}
        <div className={styles.wrapper}>
          <Sidebar sidebarIsOpen={sidebarOpen} handleSideBar={(val) => setSidebarOpen(val)} />
          <div className={`d-flex flex-column ${styles.contentWrapper}`}>
            <Topbar handleSideBar={(val) => setSidebarOpen(val)} />
            <Outlet context={{ props: propsToPass }} />
          </div>
        </div>
      </div>
    );
  }
  function BOSideTop() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;
    const [sidebarOpen, setSidebarOpen] = useState(true);

    useEffect(() => {
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    cacheCleaner(dispatch, navigate, auth);

    return auth === '' ? (
      <Navigate to='/account/login' replace />
    ) : !lengthCurrentUserClaim ? (
      <div className={styles.wrapper}>
        <Sidebar sidebarIsOpen={sidebarOpen} handleSideBar={(val) => setSidebarOpen(val)} />
        <div className={styles.contentWrapper}>
          <Topbar handleSideBar={(val) => setSidebarOpen(val)} />
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      </div>
    ) : (
      <Navigate to='/unauthorized' replace />
    );
  }
  const StickyWrapper = () => {
    const areaEnum = useSelector((state) => state.onboard.enum);
    return (
      <div className='sticky-wrapper' style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <Banner userArea={areaEnum} />
        <Navbar />
      </div>
    );
  };
  function LandingNavFoot() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const auth = useSelector((state) => state.auth.auth);
    const userPackage = useSelector((state) => state.userPackage);
    const checkHasItems = userPackage.isPackageComplete;
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;
    const isFirstRegisterLanding = () => getCookieItem(storageKeys.firstRegisterLanding);
    const fromRegisterLanding = isFirstRegisterLanding();
    let path;
    const userArea = sessionStorage.getItem(storageKeys.userArea);
    const tiktok = sessionStorage.getItem(storageKeys.tiktok) === storageValues.tiktok;
    const invitation = sessionStorage.getItem(storageKeys.invitation) === storageValues.invitation;
    const wheel = sessionStorage.getItem('wheelNavigate') === 'true';

    cacheCleaner(dispatch, navigate, auth);
    useMarketingScript();

    if (checkHasItems) {
      const getValues = setAreaDetails(userPackage.packageData.userArea);
      path = `${getValues.path}/speakers`;
    } else if (wheel && lengthCurrentUserClaim) {
      path = '/wheel-of-fortune';
    } else if (tiktok && lengthCurrentUserClaim) {
      path = `${userArea?.toLowerCase()}/tiktok`;
    } else if (invitation && lengthCurrentUserClaim) {
      path = `/gift-code`;
    } else {
      if (userArea && userArea !== '' && userArea !== UserAreasSring.None) {
        path = `/${userArea?.toLowerCase()}/dashboard`;
      } else {
        path = '/dashboard';
      }
    }

    const [FooterComponent, setFooterComponent] = useState(null);
    const footerRef = useRef(null);
    const isFooterVisible = useIntersectionObserver(footerRef);

    useEffect(() => {
      if (isFooterVisible && !FooterComponent) {
        const loadFooter = async () => {
          const module = await import('./components/Footer/Footer.jsx');
          setFooterComponent(() => module.default);
        };
        loadFooter();
      }
    }, [isFooterVisible, FooterComponent]);

    useEffect(() => {
      const storageKey = JSON.parse(sessionStorage.getItem(storageKeys.utmDataInsert));
      if (userInfo?.id !== undefined && storageKey?.value && storageKey?.form === formActions.register.key) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' || (fromRegisterLanding === 'true' && !checkHasItems) ? (
      <React.Fragment>
        <StickyWrapper />
        <Outlet />
        <div ref={footerRef}>{FooterComponent && <FooterComponent />}</div>
      </React.Fragment>
    ) : (
      <Navigate to={path} replace state={{ createData: userPackage.packageData }} />
    );
  }
  function CommonPages() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userInfo = useSelector((state) => state.user.getById);
    const areaEnum = useSelector((state) => state.onboard.enum);
    const auth = useSelector((state) => state.auth.auth);
    const currentUserClaim = useSelector((state) => state.auth.claim);
    const lengthCurrentUserClaim = currentUserClaim?.length === 0;
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const [FooterComponent, setFooterComponent] = useState(null);
    const footerRef = useRef(null);
    const isFooterVisible = useIntersectionObserver(footerRef);

    cacheCleaner(dispatch, navigate, auth);
    useMarketingScript();

    useEffect(() => {
      if (isFooterVisible && !FooterComponent) {
        const loadFooter = async () => {
          const module = await import('./components/Footer/Footer.jsx');
          setFooterComponent(() => module.default);
        };
        loadFooter();
      }
    }, [isFooterVisible, FooterComponent]);

    useEffect(() => {
      const storageKey = JSON.parse(sessionStorage.getItem(storageKeys.utmDataInsert));
      if (userInfo?.id !== undefined && storageKey?.value && storageKey?.form === formActions.register.key) {
        utmDataSender(userInfo, formActions.register);
      }
    }, [userInfo]);

    useEffect(() => {
      const utmId = queryParams.get('utm_id');
      if (
        utmId !== null &&
        utmId !== '' &&
        utmId !== undefined &&
        sessionStorage.getItem(storageKeys.utmData) === null
      ) {
        utmDataBuilder(utmId);
      }
      const hardReset = localStorage.getItem(storageKeys.hardReset);
      if (hardReset) {
        localStorage.removeItem(storageKeys.hardReset);
        refreshPage();
      }
      window.scrollTo(0, 0);
    }, []);

    return auth === '' ? (
      <React.Fragment>
        <StickyWrapper />
        <Outlet />
        <div ref={footerRef}>{FooterComponent && <FooterComponent />}</div>
      </React.Fragment>
    ) : lengthCurrentUserClaim ? (
      <React.Fragment>
        <Banner userArea={areaEnum} />
        <div className={styles.wrapper}>
          <Sidebar sidebarIsOpen={sidebarOpen} handleSideBar={(val) => setSidebarOpen(val)} />
          <div className={styles.contentWrapper}>
            <Topbar handleSideBar={(val) => setSidebarOpen(val)} />
            <Outlet />
          </div>
        </div>
      </React.Fragment>
    ) : (
      <Navigate to='/unauthorized' replace />
    );
  }
  const routes = [
    {
      element: <LandingNavFoot />,
      errorElement: <Landing.Error404 />,
      children: [
        { path: '/', element: <Landing.Main /> },
        { path: '/main', element: <Landing.Main /> },
        { path: '/form-success', element: <Landing.Main /> },
        { path: '/account/register', element: <Landing.Register /> },
        { path: '/account/login', element: <Landing.Login /> },
        { path: '/career/create', element: <Landing.BecomeASpeaker /> },
        { path: '/faq', element: <Landing.Faq /> },
        { path: '/online', element: <Landing.Online /> },
        { path: '/kids', element: <Landing.Kids /> },
        { path: '/cafe', element: <Landing.Cafe /> },
        { path: '/company', element: <Landing.Company /> },
        { path: '/cafe/branch/kadikoy', element: <Landing.Kadikoy /> },
        { path: '/cafe/branch/antalya', element: <Landing.Antalya /> },
        { path: '/cafe/branch/besiktas', element: <Landing.Besiktas /> },
        { path: '/cafe/branch/camlica', element: <Landing.Camlica /> },
        { path: '/cafe/branch/mecidiyekoy', element: <Landing.Mecidiyekoy /> },
        { path: '/campaigns', element: <Landing.Campaigns /> },
        { path: '/account/forgotpassword', element: <Landing.ForgotPassword /> },
        { path: '/campaign/detail/:name', element: <Landing.CampaignDetail /> },
        { path: '/become-a-partner', element: <Landing.CompanyForm /> },
        { path: '/onboarding', element: <Landing.OnBoarding /> },
        { path: '/lead-form', element: <Landing.LeadForm /> },
        ...metaVideoaskPaths.map((path, index) => ({
          key: `${index}_${path.videoId}`,
          path: path.path,
          element: <Landing.VideoAsk videoId={path.videoId} />,
        })),
        ...codeMetaVideoaskPaths.map((path, index) => ({
          key: `${index}_${path.videoId}`,
          path: path.path,
          element: <Landing.VideoAsk videoId={path.videoId} />,
        })),
        ...googleVideoaskPaths.map((path, index) => ({
          key: `${index}_${path.videoId}`,
          path: path.path,
          element: <Landing.VideoAsk videoId={path.videoId} />,
        })),
        { path: '/bring-your-friend', element: <Landing.BringYourFriend /> },
        ...(auth === '' ? [{ path: '/account/verify', element: <Landing.VerifyEmail /> }] : []),
        ...landingPaths.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <Landing.LandingPages />,
        })),
      ],
    },
    {
      element: <Dashboard />,
      errorElement: <Landing.Error404 />,
      children: [
        ...dashboardPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.Dashboard />,
        })),
        { path: '/profile/change-password', element: <UI.ChangePassword /> },
        { path: '/profile/personal-information', element: <UI.PersonalInformation /> },
      ],
    },
    {
      element: <UISideTopFoot />,
      errorElement: <Landing.Error404 />,
      children: [
        ...orderBasketPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.OrderBasket />,
        })),
        ...orderPaymentDetailPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.Buy />,
        })),
        ...schedulePath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.Schedule />,
        })),
        ...sessionsPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.MySessions />,
        })),
        ...ordersPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.MyOrders />,
        })),
        ...(auth !== '' ? [{ path: '/account/verify', element: <Landing.VerifyEmail /> }] : []),
        { path: '/3d-result', element: <UI.Result3D /> },
        { path: '/payment/callback', element: <UI.Callback /> },
        { path: '/congratulations', element: <UI.Congratulations /> },
        { path: '/gift-code', element: <UI.GiftCode /> },
        { path: '/profile/settings', element: <UI.ProfileSettings /> },
        { path: '/my-sessions-history', element: <UI.MySessionsHistory /> },
        { path: '/support', element: <UI.Support /> },
      ],
    },
    {
      element: <BOSideTop />,
      errorElement: <Landing.Error404 />,
      children: [
        { path: '/order-history/:attendeeId?', element: <BackOffice.AttendeeOrderHistory /> },
        { path: '/meetings/:attendeeId?/:orderItemId?', element: <BackOffice.MeetingListByAttendee /> },
        { path: '/meeting-schema/meetings/:schemaId?', element: <BackOffice.MeetingListBySchema /> },
        { path: '/operations', element: <BackOffice.OperationManagment /> },
        { path: '/meeting-schedule', element: <BackOffice.ScheduleMeetings /> },
        { path: '/monthly-schedule', element: <BackOffice.ScheduleManager /> },
        { path: '/career-list', element: <BackOffice.CareerList /> },
        { path: '/speaker-create', element: <BackOffice.SpeakerCreate /> },
        { path: '/speaker-update', element: <BackOffice.SpeakerUpdate /> },
        { path: '/speaker-effort-report', element: <BackOffice.SpeakerEffortReport /> },
        { path: '/speaker-effort-details', element: <BackOffice.SpeakerEffortDetails /> },
        { path: '/speaker-list', element: <BackOffice.SpeakerList /> },
        { path: '/availability-calendar', element: <BackOffice.AvailabilityCalendar /> },
        { path: '/attendee-create', element: <BackOffice.AttendeeCreate /> },
        { path: '/attendee-update', element: <BackOffice.AttendeeUpdate /> },
        { path: '/attendee-list', element: <BackOffice.AttendeeList /> },
        { path: '/manager-list', element: <BackOffice.ManagerList /> },
        { path: '/manager-create', element: <BackOffice.ManagerCreate /> },
        { path: '/manager-update', element: <BackOffice.ManagerUpdate /> },
        { path: '/meeting-schema-list', element: <BackOffice.MeetingSchemaList /> },
        { path: '/meeting-schema-create', element: <BackOffice.MeetingSchemaCreate /> },
        { path: '/meeting-schema-update', element: <BackOffice.MeetingSchemaUpdate /> },
        { path: '/created-today-trials', element: <BackOffice.CreatedTodayTrials /> },
        { path: '/upcoming-trials', element: <BackOffice.UpcomingTrials /> },
        { path: '/order-list', element: <BackOffice.OrderList /> },
        { path: '/order-item-list', element: <BackOffice.OrderItemList /> },
        { path: '/order-item-update', element: <BackOffice.OrderItemUpdate /> },
        { path: '/order-create', element: <BackOffice.OrderCreate /> },
        { path: '/order-update', element: <BackOffice.OrderUpdate /> },
        { path: '/order-payments', element: <BackOffice.Payments /> },
        { path: '/remaining-order-calculation', element: <BackOffice.RemainingOrderCalculation /> },
        { path: '/eft-payments', element: <BackOffice.EftPayments /> },
        { path: '/branch-holiday-create', element: <BackOffice.BranchHolidayCreate /> },
        { path: '/branch-holiday-update', element: <BackOffice.BranchHolidayUpdate /> },
        { path: '/branch-holiday-list', element: <BackOffice.BranchHolidayList /> },
        { path: '/call-them', element: <BackOffice.CallThem /> },
        { path: '/test-redate', element: <BackOffice.TestRedate /> },
        { path: '/test-price', element: <BackOffice.TestPrice /> },
        { path: '/test-package', element: <BackOffice.Packages /> },
        { path: '/age-interval-list', element: <BackOffice.AgeIntervalList /> },
        { path: '/age-interval-create', element: <BackOffice.AgeIntervalCreate /> },
        { path: '/age-interval-update', element: <BackOffice.AgeIntervalUpdate /> },
        { path: '/location-list', element: <BackOffice.LocationList /> },
        { path: '/location-create', element: <BackOffice.LocationCreate /> },
        { path: '/location-update', element: <BackOffice.LocationUpdate /> },
        { path: '/session-category-list', element: <BackOffice.SessionCategoryList /> },
        { path: '/session-category-create', element: <BackOffice.SessionCategoryCreate /> },
        { path: '/session-category-update', element: <BackOffice.SessionCategoryUpdate /> },
        { path: '/session-package-list', element: <BackOffice.SessionPackageList /> },
        { path: '/session-package-create', element: <BackOffice.SessionPackageCreate /> },
        { path: '/session-package-update', element: <BackOffice.SessionPackageUpdate /> },
        { path: '/times-per-month-list', element: <BackOffice.TimesPerMonthList /> },
        { path: '/times-per-month-create', element: <BackOffice.TimesPerMonthCreate /> },
        { path: '/times-per-month-update', element: <BackOffice.TimesPerMonthUpdate /> },
        { path: '/times-per-week-list', element: <BackOffice.TimesPerWeekList /> },
        { path: '/times-per-week-create', element: <BackOffice.TimesPerWeekCreate /> },
        { path: '/times-per-week-update', element: <BackOffice.TimesPerWeekUpdate /> },
        { path: '/zone-list', element: <BackOffice.ZoneList /> },
        { path: '/zone-create', element: <BackOffice.ZoneCreate /> },
        { path: '/zone-update', element: <BackOffice.ZoneUpdate /> },
        { path: '/banner-create', element: <BackOffice.BannerCreate /> },
        { path: '/banner-update', element: <BackOffice.BannerUpdate /> },
        { path: '/banner-list', element: <BackOffice.BannerList /> },
        { path: '/backoofice-chatroom', element: <UI.MySessions /> },
        { path: '/segment-editor', element: <BackOffice.SegmentEditor /> },
        { path: '/feedback-list', element: <BackOffice.FeedbackList /> },
        { path: '/lead-list', element: <BackOffice.LeadList /> },
        { path: '/lead-update', element: <BackOffice.LeadUpdate /> },
        { path: '/coupon-code-create', element: <BackOffice.CouponCreate /> },
        { path: '/coupon-code-update', element: <BackOffice.CouponUpdate /> },
        { path: '/coupon-code-list', element: <BackOffice.CouponList /> },
        { path: '/company-list', element: <BackOffice.CompanyList /> },
        { path: '/company-create', element: <BackOffice.CompanyCreate /> },
        { path: '/company-update', element: <BackOffice.CompanyUpdate /> },
        { path: '/company-order-list', element: <BackOffice.CompanyOrderList /> },
        { path: '/company-order-create', element: <BackOffice.CompanyOrderCreate /> },
        { path: '/company-order-update', element: <BackOffice.CompanyOrderUpdate /> },
        { path: '/campaign-list', element: <BackOffice.CampaignList /> },
        { path: '/campaign-create', element: <BackOffice.CampaignCreate /> },
        { path: '/campaign-update', element: <BackOffice.CampaignUpdate /> },
        { path: '/reporting-list', element: <BackOffice.RepotingList /> },
        { path: '/company-reporting', element: <BackOffice.CompanyReporting /> },
        { path: '/merge-cvs', element: <BackOffice.MergeCSV /> },
        { path: '/company-form-list', element: <BackOffice.CompanyFormList /> },
      ],
    },
    {
      element: <CommonPages />,
      errorElement: <Landing.Error404 />,
      children: [
        ...orderPackagePath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.Subscriptions />,
        })),
        ...orderTriallessonPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.Subscriptions />,
        })),
        ...groupPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.GroupPackage />,
        })),
        ...privatePath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.PrivatePackage />,
        })),
        ...speakersPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.Mentors />,
        })),
        ...speakerDetailsPath.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.MentorDetails />,
        })),
        { path: '/calendar', element: <UI.Calendar /> },
        { path: '/gizlilik-politikasi', element: <UI.Privacy /> },
        { path: '/satis-sozlesmesi', element: <UI.PaymentPolicy /> },
        { path: '/uyelik-sozlesmesi', element: <UI.MembershipPolicy /> },
        { path: '/cerez-politikasi', element: <UI.CookiePolicy /> },
        { path: '/kullanim-kosullari', element: <UI.TermsOFUse /> },
        ...tiktokPaths.map((path, index) => ({
          key: `${index}_${path}`,
          path: path,
          element: <UI.Tiktok />,
        })),
        // { path: '/wheel-of-fortune', element: <Landing.WheelOfFortune /> },
      ],
    },
    {
      path: '/unauthorized',
      element: <Landing.Unauthorized />,
    },
    {
      path: '/download',
      element: <Landing.DownloadPage />,
    },
    {
      path: '/sitemap',
      element: <Landing.Sitemap />,
    },
    {
      path: '*',
      element: <Landing.Error404 />,
    },
  ];

  if (pathArray) {
    let paths = [];

    routes.forEach((route, i) => {
      if (i !== 3) {
        if (route.path) {
          paths.push(route.path);
        } else if (route.children) {
          route?.children?.forEach((child) => {
            paths.push(child.path);
          });
        }
      }
    });
    return paths;
  } else {
    return routes;
  }
};

export default routes;
